import { type ConnectDragSource } from 'react-dnd';
import useSWRImmutable from 'swr/immutable';

import { EnumsSharedAssetPurpose } from '@lp-lib/api-service-client/public';

import { apiService } from '../../services/api-service';
import { uuidv4 } from '../../utils/common';
import { DragDropList } from '../common/DragDrop';
import { ArrowRightIcon } from '../icons/Arrows';
import { DeleteIcon } from '../icons/DeleteIcon';
import { MenuIcon } from '../icons/MenuIcon';
import { useOpenShareAssetPickerModal } from '../SharedAsset';
import { type FAQGroup } from './types';
import { FAQGroupUtils } from './utils';

function FAQGroupEditor(props: {
  group: FAQGroup;
  drag: ConnectDragSource;
  onDelete: () => void;
}) {
  const { group, drag, onDelete } = props;

  return (
    <div className='w-full flex items-center gap-2'>
      <div className='flex-1 bg-secondary rounded-xl p-3 flex items-center gap-3.5'>
        <button type='button' ref={drag} className='btn cursor-move'>
          <MenuIcon />
        </button>
        <div className='flex-1'>{group.label}</div>
        <a
          href={`/admin/toolkit/shared-media?id=${group.id}`}
          target='_blank'
          rel='noreferrer'
        >
          <ArrowRightIcon />
        </a>
      </div>
      <button
        type='button'
        onClick={onDelete}
        className='w-7.5 h-7.5 rounded-lg border border-secondary btn flex justify-center items-center text-red-002 bg-black'
      >
        <DeleteIcon />
      </button>
    </div>
  );
}

export function FAQGroupsEditor(props: {
  title: string;
  groups: FAQGroup[];
  onMove: (from: number, to: number) => void;
  onDelete: (index: number) => void;
  onAdd: (group: FAQGroup) => void;
}) {
  const { title, groups, onMove } = props;

  const openShareAssetPickerModal = useOpenShareAssetPickerModal();

  const handleAdd = () => {
    openShareAssetPickerModal({
      purposes: [EnumsSharedAssetPurpose.SharedAssetPurposeFaqGroup],
      onSelected: (item) => {
        props.onAdd(FAQGroupUtils.FromSharedAsset(item));
      },
    });
  };

  return (
    <div className='w-full'>
      <div className='mb-2 font-bold'>{title}</div>
      <div className='w-full flex flex-col gap-2'>
        <DragDropList
          type={`message-template-buttons-${uuidv4()}`}
          items={groups}
          onMove={onMove}
          render={({ item, index, drag, ref, style }) => (
            <div className={`w-full`} ref={ref} style={style}>
              <FAQGroupEditor
                group={item}
                drag={drag}
                onDelete={() => props.onDelete(index)}
              />
            </div>
          )}
        />
      </div>
      <button
        type='button'
        className='mt-2 btn text-sms text-secondary'
        onClick={handleAdd}
      >
        + Add FAQ Pack
      </button>
    </div>
  );
}

export function LoadableFAQGroupEditor(props: {
  id: string;
  drag: ConnectDragSource;
  onDelete: () => void;
}) {
  const { data: group, isLoading } = useSWRImmutable(
    `/faq-groups/${props.id}`,
    async () => {
      const resp = await apiService.media.getSharedAsset(props.id);
      return FAQGroupUtils.FromSharedAsset(resp.data.sharedAsset);
    }
  );

  if (isLoading || !group) return null;

  return (
    <FAQGroupEditor group={group} drag={props.drag} onDelete={props.onDelete} />
  );
}

export function LoadableFAQGroupsEditor(props: {
  title: string;
  ids: string[];
  onChange: (ids: string[]) => void;
}) {
  const openShareAssetPickerModal = useOpenShareAssetPickerModal();

  const handleMove = (from: number, to: number) => {
    const newIds = [...props.ids];
    newIds.splice(to, 0, newIds.splice(from, 1)[0]);
    props.onChange(newIds);
  };

  const handleDelete = (index: number) => {
    const newIds = [...props.ids];
    newIds.splice(index, 1);
    props.onChange(newIds);
  };

  const handleAdd = () => {
    openShareAssetPickerModal({
      purposes: [EnumsSharedAssetPurpose.SharedAssetPurposeFaqGroup],
      onSelected: (item) => {
        props.onChange([item.id, ...props.ids]);
      },
    });
  };

  return (
    <div className='w-full'>
      <div className='mb-2 font-bold'>{props.title}</div>
      <div className='w-full flex flex-col gap-2'>
        <DragDropList
          type={`faq-group`}
          items={props.ids.map((id) => ({ id }))}
          onMove={handleMove}
          render={({ item, index, drag, ref, style }) => (
            <div className={`w-full`} ref={ref} style={style}>
              <LoadableFAQGroupEditor
                id={item.id}
                drag={drag}
                onDelete={() => handleDelete(index)}
              />
            </div>
          )}
        />
      </div>
      <button
        type='button'
        className='mt-2 btn text-sms text-secondary'
        onClick={handleAdd}
      >
        + Add FAQ Pack
      </button>
    </div>
  );
}
